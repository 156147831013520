import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const getCapPatternSlice = createSlice({
  name: "getCapPattern",
  initialState,
  reducers: {
    getCapPatternStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getCapPatternSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getCapPatternFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
   
  },
});

export const {
  getCapPatternStart,
  getCapPatternSuccess,
  getCapPatternFailure,
  clearError,
} = getCapPatternSlice.actions;

export default getCapPatternSlice.reducer;