import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const getPayementIntentSlice = createSlice({
  name: "getPayementIntent",
  initialState,
  reducers: {
    getPayementIntentStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getPayementIntentSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getPayementIntentFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  getPayementIntentStart,
  getPayementIntentSuccess,
  getPayementIntentFailure,
  clearError,
} = getPayementIntentSlice.actions;

export default getPayementIntentSlice.reducer;
