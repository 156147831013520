import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postCustomShirtStart,
  postCustomShirtSuccess,
  postCustomShirtFailure,
  clearLoading,
} from "../Reducer/postCustomShirtReducer";
import axios from "axios";
export const postCustomShirt = createAsyncThunk(
  "postCustomShirt",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(postCustomShirtStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}CreatedDesign`,
        formData
      );
      const responseData = await response;

      thunkAPI.dispatch(postCustomShirtSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postCustomShirtFailure(error.response.data.message));
      thunkAPI.dispatch(clearLoading());
      throw error;
    }
  }
);
