import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react'

const Loaders = () => {
    return (
        <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: '#fff' }} spin />} />
        </div>
    )
}

export default Loaders
