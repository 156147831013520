import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: {},
  message: null,
};

const postCustomerInfoSlice = createSlice({
  name: "postCustomerInfo",
  initialState,
  reducers: {
    postCustomerInfoStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    postCustomerInfoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.message = action.payload.data.message;
    },
    postCustomerInfoFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.message = null;
    },
  },
});

export const {
  postCustomerInfoStart,
  postCustomerInfoSuccess,
  postCustomerInfoFailure,
  clearState,
} = postCustomerInfoSlice.actions;

export default postCustomerInfoSlice.reducer;
