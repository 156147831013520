import React, { useEffect, useRef, useState } from "react";
import { Button, Form, message } from "antd";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { getPayementIntent } from "../../Redux/Action/getPayementIntent";
import { postBuyShirt } from "../../Redux/Action/postBuyShirt";
import { clearState } from "../../Redux/Reducer/postBuyShirtReducer";
import { useNavigate, useParams } from "react-router-dom";
import Loaders from "../Loader/Loaders";
import { postCustomerInfo } from "../../Redux/Action/postCustomerInfo";

const StripePaymentForm = ({ designData, customerData }) => {
  const params = useParams();

  const svgRef = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadings, setLoading] = useState(false);
  let intent = useSelector((state) => state.getPayementIntent.data);
  let { error, success, loading } = useSelector((state) => state.postBuyShirt);
  let data = useSelector((state) => state.postCustomerInfo.data);
  let IntentError = useSelector((state) => state.getPayementIntent.error);
  const [imageSrc, setImageSrc] = useState("");
  const [svgSrc, setSvgSrc] = useState("");

  const handleImageLoad = () => {
    const imageData = localStorage.getItem("pngImage");
    const svgData = localStorage.getItem("svgImage");
    setImageSrc(imageData);
    setSvgSrc(svgData);
  };

  useEffect(() => {
    handleImageLoad();
  }, [imageSrc, svgSrc]);
  // useEffect(() => {
  //   dispatch(getPayementIntent(data._id));
  // }, [dispatch]);

  const onFinish = async () => {
    setLoading(true);
    try {
      const customerResponse = await dispatch(
        postCustomerInfo(customerData)
      ).unwrap();
      const customerId = customerResponse?.data?.data?._id;

      await dispatch(getPayementIntent(customerId));
      const { paymentIntent, error } = await stripe.confirmCardPayment(intent, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      if (paymentIntent) {
        const id = paymentIntent.id;
        const formData = new FormData();
        formData.append("paymentMethod", "stripe");
        formData.append("id", id);
        formData.append("amount", 1000);
        formData.append("CustomerId", customerId);
        formData.append("DesignId", designData._id);

        await dispatch(postBuyShirt(formData));
      }
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (IntentError) {
      dispatch(getPayementIntent(data._id));
    }
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      dispatch(clearState());
      navigate("/thank-you");
    }
  }, [IntentError, success, error]);

  return (
    <>
      <Form onFinish={onFinish}>
        <Form.Item name="form">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#000000",
                  "::placeholder": {
                    color: "#A9A9A9",
                  },
                },
              },
            }}
            onChange={(event) => {
              console.log("CardElement [change] event:", event);

              // Accessing card number, CVC, and ZIP code when available
              if (event.complete && event.value && event.value.card) {
                // Handle card details if needed
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="pkgbtn"
            disabled={!stripe || loading}
          >
            {loading ? <Loaders /> : "Pay Now"}
          </Button>
          <br />
        </Form.Item>
      </Form>
      <div className="container-fluid ddisplaay" style={{ display: "none" }}>
        <img id={"my-img"} ref={svgRef} src={imageSrc} alt="Displayed" />

        <div className="Pricee">
          <p className="big">$10.00</p>
        </div>
      </div>

      {/* <svg
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
      >
        <g dangerouslySetInnerHTML={{ __html: svgSrc }} />
      </svg> */}
    </>
  );
};

export default StripePaymentForm;
