import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Store';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const stripePromise = loadStripe(
  "pk_test_51NaHE9ElW1Dvtp1O01PSNQnox9j1XQ5jfjLCNQTWn1CmNlCZw38JxoDbDZ8U7ut9mkFMUqdFamSkUYax3wOEEIQz00m65OcohO"
);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
        >
          {" "}
          <App />
        </PayPalScriptProvider>
      </Elements>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();