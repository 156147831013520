import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const getColorSlice = createSlice({
  name: "getColor",
  initialState,
  reducers: {
    getColorStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getColorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getColorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
   
  },
});

export const {
  getColorStart,
  getColorSuccess,
  getColorFailure,
  clearError,
} = getColorSlice.actions;

export default getColorSlice.reducer;