import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const getBodyPatternSlice = createSlice({
  name: "getBodyPattern",
  initialState,
  reducers: {
    getBodyPatternStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getBodyPatternSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getBodyPatternFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
   
  },
});

export const {
  getBodyPatternStart,
  getBodyPatternSuccess,
  getBodyPatternFailure,
  clearError,
} = getBodyPatternSlice.actions;

export default getBodyPatternSlice.reducer;