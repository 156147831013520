import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getColorStart,
  getColorSuccess,
  getColorFailure,
} from "../Reducer/getColorReducer";
import axios from "axios";

export const getColor = createAsyncThunk(
  "getColor",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getColorStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ColorListing?size=1000&Status=true`
      );

      const responseData = await response;

      thunkAPI.dispatch(getColorSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getColorFailure(error.response.data.message));

      throw error;
    }
  }
);