import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postCustomerInfoStart,
  postCustomerInfoSuccess,
  postCustomerInfoFailure,
} from "../Reducer/postCustomerInfoReducer";
import axios from "axios";
export const postCustomerInfo = createAsyncThunk(
  "postCustomerInfo",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postCustomerInfoStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}CreateCustomerInfo`,
        values
      );
      const responseData = await response;

      thunkAPI.dispatch(postCustomerInfoSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postCustomerInfoFailure(error.response.data.message));

      throw error;
    }
  }
);
