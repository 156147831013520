import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCustomerDesignStart,
  getCustomerDesignSuccess,
  getCustomerDesignFailure,
} from "../Reducer/getCustomerDesignReducer.js";
import axios from "axios";

export const getCustomerDesign = createAsyncThunk(
  "getCustomerDesign",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCustomerDesignStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}SingleDesign/${id}`
      );

      const responseData = await response;

      thunkAPI.dispatch(getCustomerDesignSuccess(responseData?.data.data));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getCustomerDesignFailure(error.response.data.message));

      throw error;
    }
  }
);
