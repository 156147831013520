import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPayementIntentStart,
  getPayementIntentSuccess,
  getPayementIntentFailure,
} from "../Reducer/getPayementIntentReducer";
import axios from "axios";

export const getPayementIntent = createAsyncThunk(
  "getPayementIntent",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPayementIntentStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PaymentIntent/${id}`
      );

      const responseData = await response;

      thunkAPI.dispatch(getPayementIntentSuccess(response.data.clientSecret));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getPayementIntentFailure(error.response.data.message));

      throw error;
    }
  }
);
