import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postNewsLetterStart,
  postNewsLetterSuccess,
  postNewsLetterFailure,
  clearLoading,
} from "../Reducer/postNewsLetterReducer";
import axios from "axios";
export const postNewsLetter = createAsyncThunk(
  "postNewsLetter",
  async (email, thunkAPI) => {
    try {
      thunkAPI.dispatch(postNewsLetterStart());

      const response = await axios.post(
        `https://sumairroudani.com/api/v1/addnewsletter`,
        email
      );
      const responseData = await response;

      thunkAPI.dispatch(postNewsLetterSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postNewsLetterFailure(error.response.data.message));
      thunkAPI.dispatch(clearLoading());
      throw error;
    }
  }
);
