import React, { Fragment, useEffect } from "react";
import logo from "../../assets/images/footerLogo.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import youtube from "../../assets/images/youtube.png";
import { Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { postNewsLetter } from "../../Redux/Action/postNewsLetter";
import { clearState } from "../../Redux/Reducer/postNewsLetterReducer";

const Footer = () => {
  const { error, data, success } = useSelector((state) => state.postNewsLetter);

  const dispatch = useDispatch();
  const [form] = useForm();
  const onFinish = (values) => {
    form.resetFields();
    dispatch(postNewsLetter(values));
  };
  useEffect(() => {
    if (error) {
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      dispatch(clearState());
    }
  }, [error, success]);

  return (
    <Fragment>
      <div className="container-fluid footer">
        <div className="row footerOne">
          <div className="col-sm-3">
            <img src={logo} alt="" className="logoFooter" />
            <p className="footerText">
              World's first multi-lingual pure Arabian and thoroughbred horse
              racing platform for news, updates, and analysis. Access accurate
              and up-to-date real-time insights and free-of-cost global horse
              racing competitions.
            </p>
          </div>
          <div className="col-sm-3 lisst">
            <p className="headFooter">Quicklinks</p>
            <ul>
              <li>
                <Link target="_blank" to="https://www.saddlebattles.com/about">
                  About
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.saddlebattles.com/racecards"
                >
                  Race Card
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.saddlebattles.com/result">
                  Result
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.saddlebattles.com/racecourses"
                >
                  Race Course
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.saddlebattles.com/competition"
                >
                  Competition
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3 contact lisst">
            <p className="headFooter">Contact</p>
            <ul>
              <li>
                <p>Dubai, UAE</p>
              </li>
              <li>
                <a href="mailto:info@saddlesbattles.com">
                  info@saddlesbattles.com
                </a>
              </li>
              <li>
                <p>Follow us</p>
              </li>
              <li>
                <div className="socialImages">
                  <Link target="_blank" to="https://twitter.com/SaddleBattles">
                    <img alt="" src={twitter} />
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/Saddlebattleofficial/"
                  >
                    <img alt="" src={facebook} />
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/saddlebattles/"
                  >
                    <img alt="" src={instagram} />
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/company/saddlebattlesofficial"
                  >
                    <img alt="" src={linkedin} />
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.youtube.com/@SaddleBattles"
                  >
                    <img alt="" src={youtube} />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-sm-3 newsLetter">
            <p className="headFooter">Subscribe to our Newsletter</p>
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Not a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <Button htmlType="submit">Submit</Button>
            </Form>
          </div>
        </div>
      </div>

      <div className="container-fluid footerTwo">
        <div className="row">
          <div className="col-sm-12">
            <p>© 2024 Copyright Equi innovations PVT LTD</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
