import {
  Button,
  Form,
  Input,
  message,
  Radio,
  Select,
  Space,
  Steps,
} from "antd";
import React, { Fragment, useState } from "react";
import { UserOutlined, IdcardOutlined } from "@ant-design/icons";
import CustomerInfoForm from "./CustomerInfoForm";
import PaymentForm from "./PaymentForm";

const Formm = ({ svgRef, data }) => {
  const [customerData, setCustomerData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(false);
    setCustomerData(values);
    setCurrentStep(1);
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    setLoading(false);
  };

  const onChange = (value) => {
    if (currentStep === 0 && !customerData.name) {
      // Prevent step change if customer data is not filled
      return;
    }
    setCurrentStep(value);
  };

  return (
    <Fragment>
      <div className="col-sm-12 formText">
        <p>
          <ul>
            <ol>
              <b>Payment Redirection:</b> This page will redirect you to PayPal
              for secure payment processing.
            </ol>
            <ol>
              <b>Payment Processing:</b> All charges will be processed through
              your PayPal account.
            </ol>
            <ol>
              <b>Delivery of Hi-Resolution Silk:</b> High-resolution silk files
              will be sent via email in .png, .jpeg, .svg, and .pdf formats.
            </ol>
            <ol>
              <b>Customer Support:</b> For any inquiries or issues, please email
              us at{" "}
              <a href="mailto:support@saddlebattles.co">
                support@saddlebattles.co
              </a>
            </ol>
          </ul>
        </p>
      </div>
      <Steps
        type="navigation"
        current={currentStep}
        onChange={onChange}
        items={[
          {
            title: "Personal Information",
            icon: <UserOutlined />,
          },
          {
            title: "Pay",
            icon: <IdcardOutlined />,
          },
        ]}
      />
      <div className="container-fluid checkout">
        {currentStep === 0 && (
          <CustomerInfoForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={loading}
            initialValues={customerData}
          />
        )}
        {currentStep === 1 && (
          <PaymentForm
            svgRef={svgRef}
            designData={data}
            customerData={customerData}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Formm;
