import React, { Fragment, useState } from 'react'
import navlogo from '../../assets/images/logoSaddleBattle.png'
import { BsCaretDownFill  } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Accordion, Offcanvas } from 'react-bootstrap';
import { Button } from 'antd';

const Navbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Fragment>
            <div className="container-fluid navbarss">
                <div className="row second desktop">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-2">
                                <Link to='/'>
                                    <img src={navlogo} alt='asfd'/>
                                </Link>
                            </div>
                            <div className="col-sm-10">
                                <ul>
                                    <li>
                                        <Link target='_blank' to='https://www.saddlebattles.com/about'>
                                            About
                                        </Link>
                                    </li>
                                    <li className='dropdown'>
                                        <p className='dropbtn'>Statistics <BsCaretDownFill /></p>
                                        <div className='dropdown-content'>
                                            <Link target='_blank' to='https://www.saddlebattles.com/atozreports'>A to Z Report</Link>
                                            <Link target='_blank' to='https://www.saddlebattles.com/hotform'>Hot Form</Link>
                                            <Link target='_blank' to='https://www.saddlebattles.com/querytool'>Query Tool</Link>
                                            <Link target='_blank' to='https://www.saddlebattles.com/trainerstatistics'>Trainer Statistics</Link>
                                            <Link target='_blank' to='https://www.saddlebattles.com/ownerstatistics'>Owner Statistics</Link>    
                                            <Link target='_blank' to='https://www.saddlebattles.com/jockeystatistics'>Jockey Statistics</Link>                            
                                            <Link target='_blank' to='https://www.saddlebattles.com/horsestatistics'>Horse Statistics</Link>     
                                            <Link target='_blank' to='https://www.saddlebattles.com/trainerjockeycombo'>Trainer Jockey Combo</Link>                                
                                            <Link target='_blank' to='https://www.saddlebattles.com/SireSnippets'>Sire Snippets</Link>         
                                        </div>
                                    </li>
                                    <li>
                                        <Link target='_blank' to='https://www.saddlebattles.com/racecards'>Race Card</Link>
                                    </li>
                                    <li>
                                        <Link target='_blank' to='https://www.saddlebattles.com/result'>Result</Link>
                                    </li>
                                    <li>
                                        <Link target='_blank' to='https://www.saddlebattles.com/racecourses'>Race Course</Link>
                                    </li>
                                    <li>
                                        <Link target='_blank' to='https://www.saddlebattles.com/competition'>Competition</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Owner Silk</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <Link target='_blank' to='https://www.saddlebattles.com/login'>Login</Link>
                        <Link target='_blank' to='https://www.saddlebattles.com/register'>Register</Link>
                    </div>
                </div>

                <div className="row second mobbile">
                    <div className="col-sm-12 offcanvaas">
                        <Link to='/'>
                            <img src={navlogo} alt='asfd' className='navImage'/>
                        </Link>

                        <Button onClick={handleShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: 'normal'}}>
                                    <g transform="scale(5.12,5.12)"><path d="M0,7.5v5h50v-5zM0,22.5v5h50v-5zM0,37.5v5h50v-5z"></path></g>
                                </g>
                            </svg>
                        </Button>
                    </div>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="row">
                                <div className="col-sm-12 offCanvasImage">
                                    <img src={navlogo} alt='asfd' />
                                </div>

                                <div className="col-sm-12 offCanvasList">
                                    <ul>
                                        <li>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/about'>
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey='1'>
                                                    <Accordion.Header>Statistics</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/atozreports'>A to Z Report</Link>
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/hotform'>Hot Form</Link>
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/querytool'>Query Tool</Link>
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/trainerstatistics'>Trainer Statistics</Link>
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/ownerstatistics'>Owner Statistics</Link>    
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/jockeystatistics'>Jockey Statistics</Link>                            
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/horsestatistics'>Horse Statistics</Link>     
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/trainerjockeycombo'>Trainer Jockey Combo</Link>                                
                                                        <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/SireSnippets'>Sire Snippets</Link>       
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </li>
                                        <li>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/racecards'>Race Card</Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/result'>Result</Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/racecourses'>Race Course</Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/competition'>Competition</Link>
                                        </li>
                                        <li>
                                            <Link onClick={handleClose} to='/'>
                                                Owner Silk
                                            </Link>
                                        </li>
                                        <li className='offCanvasBtns'>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/login'>Login</Link>
                                        </li>
                                        <li className='offCanvasBtns'>
                                            <Link target='_blank' onClick={handleClose} to='https://www.saddlebattles.com/register'>Register</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </div>
        </Fragment>
    )
}

export default Navbar