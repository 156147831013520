const mainSvg = [
  {
    id: "87ce0e0d-46f5-44c5-9d9f-9db2a787c3fe",
    name: "Shirt Pattern 01",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/a28be651051ba33a949262f280be3cd30014ac589306931e42ce1ff3c00983e5",
    navigation: "blue-jacket",
  },
  {
    id: "73a326fd-ef6b-454c-bd18-454679722e84",
    name: "Shirt Pattern 02",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/adf63a26ff9f6788ed8997b930d50b99b2d42c419360eb4d51e103c996648f35",
    navigation: "purple-jacket",
  },
  {
    id: "2fe98f98-a8ea-4929-a2ec-03e9cf8ad2c4",
    name: "Shirt Pattern 03",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/39d12a5a0bb1280ee6c8bc5bc31a671cae7646f7859456ae4a4ec5e60db43ffe",
    navigation: "white-jacket",
  },
  {
    id: "2164e36e-f863-4e4d-be2a-22950b38dee1",
    name: "Shirt Pattern 04",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/86d8652bb3315bcf325830247429dd6e846cb185ade81d3adae8c0891526a3e0",
    navigation: "plain-jacket",
  },
];
export default mainSvg;

// await db.ShirtTypeModel.bulkCreate([
//   {
//     _id: "2164e36e-f863-4e4d-be2a-22950b38dee1",
//     Name: "Shirt Pattern 01",
//     Image:
//       "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/6b0c167beb662c571b54555eda7d9577da07bc2e59b8781ebf5c52a69920d034",
//     Admin: "763108fb-632c-48e2-97f3-cc1f3af264a3",
//     createdAt: "2024-03-14T17:12:42.000Z",
//     updatedAt: "2024-05-24T07:59:45.000Z",
//     deletedAt: null,
//   },
//   {
//     _id: "2fe98f98-a8ea-4929-a2ec-03e9cf8ad2c4",
//     Name: "Shirt Pattern 02",
//     Image:
//       "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/c35432c828c136ff30fc5ea7eb3a96f38fd08771e622d7d90b83cf05b00f2dd5",
//     Admin: "763108fb-632c-48e2-97f3-cc1f3af264a3",
//     createdAt: "2024-03-14T17:12:42.000Z",
//     updatedAt: "2024-05-24T08:02:42.000Z",
//     deletedAt: null,
//   },
//   {
//     _id: "87ce0e0d-46f5-44c5-9d9f-9db2a787c3fe",
//     Name: "Shirt Pattern 03",
//     Image:
//       "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/58f73bdd0158c8697f45b2d7bd1c9db8c1e8c20faa387a5297ebdc0b4cc49ec5",
//     Admin: "763108fb-632c-48e2-97f3-cc1f3af264a3",
//     updatedAt: "2024-05-24T08:03:30.904Z",
//     createdAt: "2024-05-24T08:03:30.904Z",
//   },
//   {
//     _id: "73a326fd-ef6b-454c-bd18-454679722e84",
//     Name: "Shirt Pattern 04",
//     Image:
//       "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/732857ce1799098449d890f2daa8d218bf22b7d2bf3657e132bb0e30dba5e049",
//     Admin: "763108fb-632c-48e2-97f3-cc1f3af264a3",
//     updatedAt: "2024-05-24T08:04:12.941Z",
//     createdAt: "2024-05-24T08:04:12.941Z",
//   },
// ]);