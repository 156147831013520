import React, { Fragment } from "react";
import Shirt from "../components/Shirt/Shirts";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";

function ShirtSvg() {
    return (
        <Fragment>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <Shirt />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ShirtSvg;
