import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBodyPatternStart,
  getBodyPatternSuccess,
  getBodyPatternFailure,
} from "../Reducer/getBodyPatternReducer";
import axios from "axios";

export const getBodyPattern = createAsyncThunk(
  "getBodyPattern",
  async (ShirtType, thunkAPI) => {
    try {
      thunkAPI.dispatch(getBodyPatternStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PatternListing?PatternPlace=body&ShirtType=${ShirtType}&size=100&Status=true`
      );

      const responseData = await response;

      thunkAPI.dispatch(getBodyPatternSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getBodyPatternFailure(error.response.data.message));

      throw error;
    }
  }
);