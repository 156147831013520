import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: {},
  success: null,
};

const postBuyShirtSlice = createSlice({
  name: "postBuyShirt",
  initialState,
  reducers: {
    postBuyShirtStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    postBuyShirtSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    postBuyShirtFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  postBuyShirtStart,
  postBuyShirtSuccess,
  postBuyShirtFailure,
  clearState,
  clearLoading
} = postBuyShirtSlice.actions;

export default postBuyShirtSlice.reducer;
