import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { Country, State, City } from "country-state-city";
// import Loaders from "../Loader/Loaders";

const CustomerInfoForm = ({ onFinishFailed, onFinish, initialValues }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const { Option } = Select;
  const handleCountryChange = (value) => {
    const selectedCountry = countries.find(
      (country) => country.isoCode === value
    );
    setSelectedCountry(selectedCountry);
    setStates(State.getStatesOfCountry(value));
    setCities([]);
  };

  const validateNumber = (_, value) => {
    if (value) {
      if (!/^[0-9]+$/.test(value)) {
        return Promise.reject(
          new Error("Phone number can only contain digits.")
        );
      } else if (value.length < 9 || value.length > 15) {
        return Promise.reject(
          new Error("Phone number must be between 9 and 15 digits long.")
        );
      }
    }
    return Promise.resolve();
  };
  const handleStateChange = (value) => {
    const selectedState = states.find((state) => state.isoCode === value);
    setSelectedState(selectedState);
    setCities(City.getCitiesOfState(selectedCountry.isoCode, value));
  };
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStates(State.getStatesOfCountry(initialValues.country));
    setCities(
      City.getCitiesOfState(initialValues?.country, initialValues.state)
    );
  }, []);
  return (
    <Form
      name="checkoutForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      autoComplete="off"
      initialValues={initialValues}
      requiredMark="optional"
    >
      <div className="row contact personal">
        <div className="col-sm-12">
          <h2>Contact Information</h2>
        </div>
        <div className="col-sm-6">
          <Form.Item
            name="FirstName"
            label="FIRST NAME *"
            rules={[
              {
                required: true,
                message: "Please enter first name!...",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item
            name="LastName"
            label="LAST NAME *"
            rules={[
              {
                required: true,
                message: "Please enter last name!...",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item
            name="Email"
            label="EMAIL ADDRESS *"
            rules={[
              {
                required: true,
                message: "Please enter email address!...",
              },
              {
                type: "email",
                message: "Please enter valid email address!...",
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item
            name="MobileNo"
            label="PHONE NUMBER"
            rules={[
              {
                validator: validateNumber,
              },
            ]}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </div>

        <div className="col-sm-12">
          <Form.Item name="Address" label="ADDRESS">
            <Input placeholder="Address" />
          </Form.Item>
        </div>
        {/* <div className="col-sm-4">
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select your country!" }]}
          >
            <Select
              showSearch
              placeholder="Select Country"
              onChange={handleCountryChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {countries.map((country) => (
                <Option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div> */}

        {states.length > 0 && (
          <div className="col-sm-4">
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please select your state!" }]}
            >
              <Select
                showSearch
                placeholder="Select State"
                onChange={handleStateChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {states.map((state) => (
                  <Option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        {cities.length > 0 && (
          <div className="col-sm-4">
            <Form.Item
              name="City"
              label="City"
              rules={[{ required: true, message: "Please select your city!" }]}
            >
              <Select
                showSearch
                placeholder="Select City"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {cities.map((city) => (
                  <Option key={city.name} value={city.name}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-sm-12">
          <Button htmlType="submit">{"Proceed To Payment"}</Button>
        </div>
      </div>
    </Form>
  );
};

export default CustomerInfoForm;
