import React, { Fragment } from 'react'
import { Tabs} from 'antd'
import ShirtThreeCapMarkingSlider from '../ShirtThreeSlider/ShirtThreeCapMarkingSlider';
import ListColor from '../../ColorCompTab/ListColor';
const { TabPane } = Tabs;

const CapTabThree = ({handleCapColor, handleCapMarkingColor, handleCapId, capColors, capMarkingColor}) => {

    
    return (
        <Fragment>
            <div className="row boddyTab">
                <div className="col-sm-12">
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Cap Colour"  key="1">
                        <ListColor handleColor={handleCapColor} colorsss={capColors}/>

                        </TabPane>
                        <TabPane tab="Cap Marking" key="2" >
                            <ShirtThreeCapMarkingSlider handleCapId={handleCapId}/>
                        </TabPane>
                        <TabPane tab="Cap Marking Colour" key="3">
                        <ListColor handleColor={handleCapMarkingColor} colorsss={capMarkingColor}/>

                        </TabPane>                
                    </Tabs>        
                </div>
            </div>
        </Fragment>
    )
}

export default CapTabThree