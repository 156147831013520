import React, { Fragment } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import ShirtTwoSvg from "../components/ShirtTwo/ShirtTwoSvg";

function ShirtTwo() {
    return (
        <Fragment>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <ShirtTwoSvg />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ShirtTwo;