import { Fragment } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import BuyNow from "./Pages/BuyNow";
import ShirtSvg from "./Pages/ShirtSvg";
import ShirtOneSvg from "./Pages/ShirtOneSvg";
import ThankYou from "./Pages/ThankYou";
import ShirtThree from "./Pages/ShirtThree";
import ShirtTwo from "./Pages/ShirtTwo";

function App() {
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (
  //       event.keyCode === 123 || // F12
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 67) ||
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 74)
  //     ) {
  //       event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/plain-jacket/:id" element={<ShirtSvg />} />
          <Route exact path="/white-jacket/:id" element={<ShirtOneSvg />} />
          <Route exact path="/blue-jacket/:id" element={<ShirtTwo />} />
          <Route exact path="/purple-jacket/:id" element={<ShirtThree />} />
          <Route exact path="/buy-now/:id" element={<BuyNow />} />
          <Route exact path="/thank-you" element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
