import React, { Fragment, useState } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Button, Skeleton } from "antd";
import mainSvg from "../Data/mainsvgs";

function Homepage() {
  const Navigate = useNavigate();
  const [id, setId] = useState("");
  const [selected, setSelected] = useState();
  const [loadingState, setLoadingState] = useState(mainSvg.map(() => true));

  const handleNavigation = ({ page, id }) => {
    setSelected(page);
    setId(id);
  };

  const handleImageLoad = (index) => {
    setLoadingState((prevLoadingState) =>
      prevLoadingState.map((loading, i) => (i === index ? false : loading))
    );
  };

  const noSelectStyle = {
    userSelect: "none",
    WebkitUserSelect: "none", // Safari
    MozUserSelect: "none", // Firefox
    msUserSelect: "none", // Internet Explorer/Edge
  };

  return (
    <Fragment>
      <Navbar />
      <div className="container" style={noSelectStyle}>
        <div className="row home">
          <div className="col-sm-12">
            <h2>Owner Silk</h2>
          </div>
          <div className="col-sm-12 imageess">
            {mainSvg?.map((item, index) => (
              <div key={item.id} style={{ position: "relative" }}>
                {loadingState[index] && (
                  <Skeleton.Image active className="imageHeight" />
                )}
                <img
                  onClick={() =>
                    handleNavigation({
                      page: item.navigation,
                      id: item.id,
                    })
                  }
                  src={item.source}
                  alt=""
                  onLoad={() => handleImageLoad(index)}
                  className={selected === item.navigation ? "active" : ""}
                  style={{
                    display: loadingState[index] ? "none" : "block",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="col-sm-12 ccentre">
            <Button
              disabled={!selected ? true : false}
              onClick={() => Navigate(`/${selected}/${id}`)}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Homepage;
