import React, { Fragment, useState } from "react";
import ShirtOneTab from "./ShirtOneTab";
import ShirtOneDisplay from "./ShirtOneDisplay";

const ShirtOneSvgs = () => {
    const [bodyId, setBodyId] = useState("");
    const [sleevesID, setSleevesID] = useState("");
    const [capId, setCapId] = useState("");
  
    const [sleeveColors, setsleeveColors] = useState({
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    });
    const [bodyMarkingColors, setBodyMarkingColors] = useState({
        fill: "",
        fillRule: 'nonzero',
        stroke: '#000',
        strokeMiterlimit: '10'

    });
    const [sleevesMarkingColor, setSleevesMarkingColor] = useState({
        fill: "",
        fillRule: 'nonzero',
        stroke: '#000',
        strokeMiterlimit: '10'
    });
    const [capMarkingColor, setCapMarkingColor] = useState({
        fill: "",
        fillRule: 'nonzero',
        stroke: '#000',
        strokeMiterlimit: '10'
    });
    const [bodyColors, setBodyColors] = useState({
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    });
    const [capColors, setCapColors] = useState({
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    });
    const sleeveBorder = {
        fill: '#12242f',
        stroke: '#12242f',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    }

    const handleBodyId = (id) => {
        setBodyId(id);
    };
    const handleBodyMarkingColor = (color) => {
        const updatedBodyColors = { ...bodyColors, fill: color };
        setBodyMarkingColors(updatedBodyColors);
    };
    const handleBodyColor = (color) => {
        const updatedBodyColors = { ...bodyColors, fill: color };
        setBodyColors(updatedBodyColors);
    };
    const handleSleeveId = (id) => {
        setSleevesID(id);
    };
    
    const handleSleevesMarkingColor = (color) => {
        const updatedBodyColors = { ...sleevesMarkingColor, fill: color };
        setSleevesMarkingColor(updatedBodyColors);
    };
    const handlesleevesColor = (color) => {
        const updatedBodyColors = { ...sleeveColors, fill: color };
        setsleeveColors(updatedBodyColors);
    };

    const handleCapMarkingColor = (color) => {
        const updatedBodyColors = { ...capMarkingColor, fill: color };
        setCapMarkingColor(updatedBodyColors);
    };
    const handleCapColor = (color) => {
        const updatedCapColors = { ...capColors, fill: color };
        setCapColors(updatedCapColors);
    };
    const handleCapId = (id) => {
        setCapId(id);
    };

    return (
        <Fragment>
            <div class="container-fluid mycont">
                <div class="row noMargin">
                    <div className="col-sm-12 previeeww">
                        <div className="row noMargin">
                            <div className="col-sm-10 buttonDispaly">
                                <ShirtOneTab handleCapColor={handleCapColor} handleCapMarkingColor={handleCapMarkingColor} handleCapId={handleCapId} capColors={capColors} capMarkingColor={capMarkingColor} handlesleevesColor={handlesleevesColor} handleSleevesMarkingColor={handleSleevesMarkingColor} handleSleeveId={handleSleeveId} sleeveColors={sleeveColors} sleevesMarkingColor={sleevesMarkingColor} handleBodyColor={handleBodyColor} handleBodyMarkingColor={handleBodyMarkingColor} handleBodyId={handleBodyId} bodyColors={bodyColors} bodyMarkingColors={bodyMarkingColors}
                                />
                            </div>

                            <div className="col-sm-2 forSvg">
                                <ShirtOneDisplay  capColors={capColors} sleeveBorder={sleeveBorder} capMarkingColor={capMarkingColor} sleeveColors={sleeveColors} sleevesMarkingColor={sleevesMarkingColor}  bodyColors={bodyColors} bodyMarkingColors={bodyMarkingColors} bodyId={bodyId} capId={capId} sleevesID={sleevesID}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ShirtOneSvgs