import React, { Fragment, useEffect, useState } from 'react'
import { Button, Tabs } from 'antd'
import menuubody from "../../assets/images/p4body.png";
import menuuSleeve from "../../assets/images/p4sleeve.png";
import menuuCap from "../../assets/images/p4cap.png";
import BodyTabThree from './ShirtThreeTabs/BodyTabThree';
import SleeveTabThree from './ShirtThreeTabs/SleeveTabThree';
import CapTabThree from './ShirtThreeTabs/CapTabThree';

const ShirtThreeTabs = ({handleCapColor, handleCapMarkingColor, handleCapId, capColors, capMarkingColor, handlesleevesColor, handleSleevesMarkingColor, handleSleeveId,  sleeveColors, sleevesMarkingColor, handleBodyColor, handleBodyMarkingColor, handleBodyId, bodyColors, bodyMarkingColors}) => {
    const [tabPosition, setTabPosition] = useState('left');

    useEffect(() => {
        const handleResize = () => {
            setTabPosition(window.innerWidth < 576 ? 'top' : 'left');
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 noooPad">
              <Tabs tabPosition={tabPosition}>
                <Tabs.TabPane
                  key="1"
                  tab={
                    <Button>
                      <img src={menuubody} alt="" />
                      Body
                    </Button>
                  }
                >
                  <BodyTabThree
                    handleBodyColor={handleBodyColor}
                    handleBodyMarkingColor={handleBodyMarkingColor}
                    handleBodyId={handleBodyId}
                    bodyColors={bodyColors}
                    bodyMarkingColors={bodyMarkingColors}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="2"
                  tab={
                    <Button>
                      <img src={menuuSleeve} alt="" />
                      Sleeves
                    </Button>
                  }
                >
                  <SleeveTabThree
                    handlesleevesColor={handlesleevesColor}
                    handleSleevesMarkingColor={handleSleevesMarkingColor}
                    handleSleeveId={handleSleeveId}
                    sleeveColors={sleeveColors}
                    sleevesMarkingColor={sleevesMarkingColor}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="3"
                  tab={
                    <Button>
                      <img src={menuuCap} alt="" />
                      Cap
                    </Button>
                  }
                >
                  <CapTabThree
                    handleCapColor={handleCapColor}
                    handleCapMarkingColor={handleCapMarkingColor}
                    handleCapId={handleCapId}
                    capColors={capColors}
                    capMarkingColor={capMarkingColor}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default ShirtThreeTabs