import React, { Fragment } from "react";
// import Shirt from "../components/Shirt/Shirts";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import ShirtOneSvgs from "../components/ShirtOne/ShirtOneSvgs";

function ShirtOneSvg() {
    return (
        <Fragment>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <ShirtOneSvgs />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ShirtOneSvg;
