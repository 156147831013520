import { Button, Tooltip, message } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import restartImage from "../../assets/images/restart.png";
import backImage from "../../assets/images/backIcon.png";
import { CopyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LottieLoader from "../Loader/LottieLoader";

const TooltipContent = ({ item, handleCopy }) => (
  <span>
    {item?.ColorCode}
    <CopyOutlined
      style={{ marginLeft: 5 }}
      onClick={(e) => handleCopy(e, item.ColorCode)}
    />
  </span>
);

const ListColor = ({ handleColor, colorsss }) => {
  const navigate = useNavigate();
  const colors = useSelector((state) => state.getColor.data);
  const loading = useSelector((state) => state.getColor.loading);

  const handleCopy = (e, colorCode) => {
    e.stopPropagation(); // Prevent triggering the handleColor onClick event
    navigator.clipboard
      .writeText(colorCode)
      .then(() => {
        message.success("Color code copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy color code.");
      });
  };

  const isColorDark = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  };

  return (
    <Fragment>
      <div className="fleexWrapColor">
        {loading ? (
          <div className="lottie-dash">
            <LottieLoader />
          </div>
        ) : (
          colors &&
          colors?.items?.map((item, i) => (
            <div key={i} className="cololrnMAe">
              <Tooltip
                title={<TooltipContent item={item} handleCopy={handleCopy} />}
                placement="top"
              >
                <div
                  className="fleexWrapColorOutsiude"
                  style={{ border: "1px solid #000" }}
                  onClick={() => handleColor(item.ColorCode)}
                >
                  <div
                    className="fleexWrapColorInside"
                    style={{ backgroundColor: item.ColorCode }}
                  >
                    {item?.ColorCode === colorsss?.fill ? (
                      <img
                        width="25"
                        height="25"
                        src={`https://img.icons8.com/material-rounded/24/checkmark--v1.png`}
                        alt="checkmark--v1"
                        style={{
                          filter: isColorDark(item.ColorCode)
                            ? "invert(1)"
                            : "invert(0)",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </Tooltip>
              <p>{item?.Name}</p>
            </div>
          ))
        )}
      </div>
      <div className="ceeenter">
        <Button className="restart" onClick={() => navigate(-1)}>
          <img src={backImage} alt="Restart" /> Go Back
        </Button>
        <Button className="restart" onClick={() => handleColor("#fff")}>
          <img src={restartImage} alt="Restart" /> Restart
        </Button>
      </div>
    </Fragment>
  );
};

export default ListColor;
