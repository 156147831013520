import React, { Fragment } from 'react'

const Message = () => {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row thankyoouu">
                    <div className="col-sm-12">
                        <h2>Thank You!</h2>
                    </div>
                    <div className="col-sm-12">
                        <p>We sincerely appreciate you choosing Silk Owner for your custom t-shirt and clothing design needs. Your support is invaluable to us, and we are dedicated to providing you with the best design experience possible. Thank you for trusting us with your creative vision!</p>
                        <p>Your unique designs showcase your creativity and passion, and we're thrilled to help bring your ideas to life. At Silk Owner, we strive to provide a seamless and enjoyable experience, ensuring your graphics are perfect and ready to impress. Our platform empowers you to express your individuality through your designs, and we are honored to be a part of your creative journey. Your satisfaction is our top priority, and we are committed to exceeding your expectations.</p>
                        <p>Thank you for being a valued customer. We look forward to seeing more of your fantastic designs in the future and continuing to support your creative endeavors!</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Message