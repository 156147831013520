import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const getSleevePatternSlice = createSlice({
  name: "getSleevePattern",
  initialState,
  reducers: {
    getSleevePatternStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getSleevePatternSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getSleevePatternFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
   
  },
});

export const {
  getSleevePatternStart,
  getSleevePatternSuccess,
  getSleevePatternFailure,
  clearError,
} = getSleevePatternSlice.actions;

export default getSleevePatternSlice.reducer;