import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { clearState } from "../../Redux/Reducer/postBuyShirtReducer";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postBuyShirt } from "../../Redux/Action/postBuyShirt";
import { postCustomerInfo } from "../../Redux/Action/postCustomerInfo";

const PayPalPaymentForm = ({ designData, customerData }) => {
  const [paymentSuccessId, setPaymentSuccessId] = useState(null);
  let { error, success } = useSelector((state) => state.postBuyShirt);
  let customerItem = useSelector((state) => state.postCustomerInfo.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onApproveHandler = async (data, actions) => {
    const customerResponse = await dispatch(
      postCustomerInfo(customerData)
    ).unwrap();
    const customerId = customerResponse?.data?.data?._id;

    const orderId = data.orderID;
    setPaymentSuccessId(orderId);
    const formData = new FormData();
    const values = {
      id: paymentSuccessId,
      paymentMethod: "paypal",
      amount: 10,
    };

    formData.append("id", orderId);
    formData.append("paymentMethod", "paypal");
    formData.append("amount", 10);
    formData.append("CustomerId", customerId);
    formData.append("DesignId", designData._id);

    await dispatch(postBuyShirt(formData));
  };
  const createOrder = (data, actions) => {
    const totalPrice = 10;

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalPrice,
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      dispatch(clearState());
      navigate("/thank-you");
    }
  }, [success, error]);
  // const email = "sb-f1g1130758837@personal.example.com";
  // const password = "An1L1<}o";

  return (
    <>
      <PayPalButtons
        onApprove={(data, actions) => onApproveHandler(data, actions)} // Add the onApprove event handler
        style={{ layout: "horizontal" }}
        createOrder={createOrder}
      />
      {/* <p>
        test Email: {email}
        <button onClick={() => copyToClipboard(email)}>Copy Email</button>
        <br />
        password: {password}
        <button onClick={() => copyToClipboard(password)}>Copy Password</button>
      </p> */}
    </>
  );
};

export default PayPalPaymentForm;
