import React, { Fragment } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import ShirtThreeSvg from "../components/ShirtThree/ShirtThreeSvg";

function ShirtThree() {
    return (
        <Fragment>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <ShirtThreeSvg />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ShirtThree;
