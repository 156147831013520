import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSleevePatternStart,
  getSleevePatternSuccess,
  getSleevePatternFailure,
} from "../Reducer/getSleevePatternReducer";
import axios from "axios";

export const getSleevePattern = createAsyncThunk(
  "getSleevePattern",
  async (ShirtType, thunkAPI) => {
    try {
      thunkAPI.dispatch(getSleevePatternStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PatternListing?PatternPlace=sleeve&ShirtType=${ShirtType}&size=100&Status=true`
      );

      const responseData = await response;

      thunkAPI.dispatch(getSleevePatternSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getSleevePatternFailure(error.response.data.message));

      throw error;
    }
  }
);