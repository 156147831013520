import React, { Fragment, useEffect, useRef } from "react";
import Formm from "../components/CheckoutForm/Formm";
import DisplaySvg from "../components/CheckoutForm/DisplaySvg";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerDesign } from "../Redux/Action/getCustomerDesign";

function Orderpage({ designData }) {
  const dispatch = useDispatch();
  const svgRef = useRef();
  const { data } = useSelector((state) => state.getCustomerDesign);
  useEffect(() => {
    dispatch(getCustomerDesign(designData));
  }, []);

  return (
    <Fragment>
      {/* <Navbar /> */}
      <div class="container-fluid">
        <div class="row ceenterr">
          <div className="col-sm-8 forrmm">
            <Formm svgRef={svgRef} data={data} />
          </div>

          <div className="col-sm-2 forrm">
            <DisplaySvg data={data} svgRef={svgRef} />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
}

export default Orderpage;
