const svgData = {
  svg: {
    elements: [
      {
        id: "left-sleeve",
        d: "M117.41,456s41.63-37.44,60.18-42.34,89.91,73.48,89.91,73.48-8.39,47.58,0,71c0,0-4.19,62.63-8.39,68.58S233.57,653,234.27,656.85s10.49,21.69,10.14,28-2.8,18.89-15.74,30.79,14,19.94,18.54,36.38-.7,78.38-.7,78.38-1,10.14,9.1,21.34c0,0,0,36.39,8.05,53.53s5.59,40.59,5.59,40.59-36.38-1.4-66.82,14.69c0,0,2.1-36.38-6-40.93S167,895.48,160.74,867.14c0,0-23.09-37.79-28.69-39.89S122,748.52,109.36,735.57c0,0-6-59.83-3.15-67.18s-13.3-21.34-9.1-56.68S100.71,470.21,117.41,456Z",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "sleeveColors",
      },
      {
        id: "right-sleeve",
        d: "M487.9,440.42a.33.33,0,0,1,.21-.43.35.35,0,0,1,.4.14c5.21,6.71,31,43,30.56,103.36-.44,66.71,16.79,54.23,20.64,96.91s-1.75,140.31,0,150.1,0,12.6-.52,17.15,5.59,7,5.77,10-5.42,8-4.2,12.24,4.91,8.4,4.2,12.77,0,59.72,8.63,75.58c0,0-38.52-4-43.75,7.46,0,0-11.53-15.16-13.4-39.42s-4.9-34.52-4.66-42.68-3.29-27.86-5.26-34.11c0,0,1.58-2.47,4.47-26.52s16.4-162.8,16.58-164.66,2.21-107.76-8-140.9C499.61,477.38,489.8,447.84,487.9,440.42Z",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "sleeveColors",
      },
      {
        id: "body",
        d: "M228.67,715.63s-7.29,11.36,8.6,22.06,9.91,84.07,9.69,87H427.4s48.52,7.72,62.28-20.27c0,0,7.7-129.69,10.49-139.49S526.07,546.64,489,440.27c0,0-54.45-51.08-81.35-56.68l-.52-45L392,354.9v38.72l-10,.06-3.2-40.53-83.62-49s-16.45-4.9-19.95,22.39-4.2,33.94-43,34.64S128.47,423,120.84,453c0,0,51-52.74,71.12-34.83s71.91,57.55,74.25,67.59-6.42,37-.76,64.67-4.76,62.93-5.37,74c0,0-20.77,23.6-23.18,27.4S240.65,676,244.22,687s-17.06,23.27-18.52,37.67",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "bodyColors",
      },
      {
        id: "body-highlight",
        d: "M385.76,393.66s8.75,51.86,9.8,63.76,9.8,70.32,9.8,85.72,1.05,59.13,1.75,72.77,7.69,77.33,9.44,90.27S432,751,428.8,781.05s-1.4,43.6-1.4,43.6",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "bodyColors",
      },
      {
        id: "cap-front",
        d: "M279,84.44c37.8-37.06,83.89-42.32,132.51-26.77C443.7,68,465.39,89.55,474.63,125.76c-40.3-21.84-80.51-24.59-120.2-8.13-26.86,11.15-52.35,26.39-77.06,42.12-11.08,7.05-18.74,19.79-28.15,30.15C246.57,151.66,249.72,113.17,279,84.44Z",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "capColors",
      },
      {
        id: "cap-bill",
        d: "M485.13,168.07c-.84,4.24-8.74,11.69-10.5,15.74-5-21.67-12.59-34.92-29.3-44.51C413,120.73,378,119.54,342.22,125.36c-2.56.43-6.34,2.17-8.89,2.59-.3-.84,1.1.16.81-.69,9.28-3.39,20.2-9.47,29.71-12,34-9.1,65.28-9.29,97.59,5.57C482.09,130.29,489.48,146.09,485.13,168.07Z",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "capColors",
      },
      {
        id: "facecut",
        d: "M474.63,176.46s-11.07-65.34-132.95-53.19c0,0-77.69,39.41-90.57,64.5s16,51,16,51S255.9,218,257.36,198.16s33.59-24.76,28-3.07-14.87,27.38-19.42,50.82S247,269.18,247,269.18s-4.73,15.41,6.82,34.65c0,0-15.22,19.23-16.27,27.45s7.7,45.14,44.78,59.13c0,0,4.55,8.23,5.08,17s20.12,8.58,32.71-1.22c0,0,3.68-4.37-23.27-6.3,0,0-3.84-1.4-3.14-12.94,0,0-21.17-31.32-29.57-39.37s-6.3-70-6.3-72.42S278.53,289,278.53,289s1.69-40.88,5.42-49.28,17.26-28.45,18-39.42,0-33.82,0-33.82S399.68,128,443.3,177.68L438.6,287s-23.8,17.49-19.42,25,9.62,14.52,24.84-2.62c0,0,7.87-3.68,4.37-19.42s-.17-67.88,3.85-73.48S475.14,189.87,476,185A13.12,13.12,0,0,0,474.63,176.46Z",
        visibility: "visible",
        className: "mainPattern",
        type: "path",
        style: "",
      },
      {
        id: "abc",
        d: "M649.9.1V1009.9H.1V.1H649.9M650,0H0V1010H650V0Z",
        visibility: "visible",
        type: "path",
        className: "",
        style: "",
      },
      {
        id: "outlinee",
        pattern: [
          {
            d: "M168.75,624.72s30.31,43.2,82.61,9.1c0,0-37.39,51.28-39.92,53.56s-4.54,13.64-27,1.51a10.21,10.21,0,0,0-10.61,5.5c-3.79,6.88-10.86-77.5-10.86-77.5S163.44,623,168.75,624.72Z",
          },
          {
            d: "M100.54,602s55.07,7.32,62.4,49.77C162.94,651.76,101,616.13,100.54,602Z",
          },
          {
            d: "M103.57,708.09s32.84,17.18,46,22.49l-40.68,13.64Z",
          },
          {
            d: "M224.07,724s-27.28,13.13-16.92,34.86,19.7,15.92,21.22,16.17-1.52-25.77-7.58-29.31S224.07,724,224.07,724Z",
          },
          {
            d: "M237,791s-23.75-9.86-34.36,4-47.49,49-49,49,42.44-23.5,46.23-26,27.54-20.21,31.33-18.19S237,791,237,791Z",
          },
          {
            d: "M171.53,849.31s59.62,13.14,63.66,17.43S173,855.63,171.53,853.36,167.09,848.32,171.53,849.31Z",
          },
          {
            d: "M223.32,904.39s4.54-22,21.72-24.26A113.5,113.5,0,0,0,232.92,895C227.11,903.63,223.32,904.39,223.32,904.39Z",
          },
          {
            d: "M169.17,891.25s31.66,30.82,56.42,34.86C225.59,926.11,193.85,927.12,169.17,891.25Z",
          },
          {
            d: "M257.8,471.25S268,459.5,273.34,459.69s12.31,21,8.9,33.35-17.8,16.29-17.8,16.29,4.75-7.39,0-20.46S257.8,471.25,257.8,471.25Z",
          },
          {
            d: "M265.32,552.22s-3.54,59.16-5.82,67.15-2.28,29.46-6.59,31.21-11.65,12-12.67,12.23S290.4,710,296,710s-28.63-37.19-42.56-42.94,9.12-24.46,18.24-11,79,131.81,114.5,135.31,53.71,28.2,52.7,33.2,17.92-7.87-19.32-24.9-55.48-27.9-63.84-42.32-49.4-74.52-64.6-88.56-30.4-30.14-30.4-37.63,14.44-30.48,12.92-45.38S265.32,552.22,265.32,552.22Z",
          },
          {
            d: "M308.33,534s-13.52,58.43,4.17,82.93,17.68,73.69,17.68,73.69,13,16.5,11.05-4.72-28-81.35-32.9-94S316.28,538.7,308.33,534Z",
          },
          {
            d: "M248,705.06s100.42,80.34,116,85.64c0,0-12,.26-36.26-18.94S256.66,719.21,248,705.06Z",
          },
          {
            d: "M245.55,749.79S276.62,798,293.8,804.6C293.8,804.6,254.39,775.82,245.55,749.79Z",
          },
          {
            d: "M450,758.62s42.05-60.69,45.74-61.93S468.82,750.53,450,758.62Z",
          },
          {
            d: "M428.59,770.26s34.11,35.1,40.8,43.18-4.23,10.29-13.58,7.38S429.85,777.1,428.59,770.26Z",
          },
          {
            d: "M476.45,804.34s17.74-71.54,19.61-76.69-12.53,72.4-15.31,76.69S475.45,808.39,476.45,804.34Z",
          },
          {
            d: "502.73 674.75 539.36 645.19 539.36 648.98 501.21 680.3 502.73 674.75",
          },
          {
            d: "M498.94,693.94s31.32,3,43.2-10.86l-.58,7.25s-28.22,20.29-33,21.3-11.12,0-11.12,0Z",
          },
          {
            d: "M493.89,750.53s21.2-11.62,28-14.14,19.21,0,19.21,0v6.51s-19.46-1.46-25.27,2.58-22,11.12-22,11.12Z",
          },
          {
            d: "M491.57,838.45A25.28,25.28,0,0,0,503,819.76c1.26-12.89-1.77,47.24,3,52.54s31.33-17.43,31.33-17.43-26.53,18.44-26.53,22.74-2.27,31.07,24,17.18-17.18,28-34.36,13.39Z",
          },
        ],
      },
      {
        id: "watermark",
        d: "M134,909.8c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L131,885l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,10.9c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.3   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.3-11.8,33.6-16.3C144.4,935.8,138.1,911,134,909.8z    M96.4,923.8l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L96.4,923.8z M70.4,916.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L70.4,916.1z M116.3,940.9l-2-7.6l-9.3,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5   c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6   c0-0.1-0.1-0.1-0.1-0.2l-13.2,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.2-9.7l3.3,12.4L116.3,940.9z",
        type: "path",
      },
      {
        id: "watermark-1",
        d: "M293.2,750.5c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.8   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4   c7.2-3,31.2-11.8,33.6-16.3C303.7,776.5,297.4,751.7,293.2,750.5z M255.6,764.5l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L255.6,764.5z    M229.7,756.8l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L229.7,756.8z M275.6,781.6c-0.6-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.8,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2   l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.3l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L275.6,781.6z",
        type: "path",
      },
      {
        id: "watermark-2",
        d: "M452.5,591.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4   l-4.4-0.8l-1.4-0.7c1.3-1.3,2.7-2.6,4-3.9l0.5-0.2l-6.8-1.9l41-18c-0.8-3.5-1.6-6.9-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5   c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6   c2.9,1.4,7.4,7.7,9.1,10.9c-1.5,4.7-3,9.3-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6   c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5c0.3,1,0.6,2.1,0.9,3.2c-13.1,5.4-25.6,9.5-36.3,8.3   c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1   c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C463,617.2,456.7,592.4,452.5,591.3z M414.9,605.3l1,0.4l-3.6,1.6l-1.1-0.6   l-1.1-3.8L414.9,605.3z M388.9,597.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1l0.8,2.9L388.9,597.6z M434.8,622.3l-2-7.6l-9.4,4.1   c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6   c0.9-0.8,1.6-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4L434.8,622.3z",
        type: "path",
      },
      {
        id: "watermark-3",
        d: "M611.7,432c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C622.2,458,615.9,433.2,611.7,432z    M574.1,446l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.9L574.1,446z M548.2,438.3l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L548.2,438.3z M594.1,463.1c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8   l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L594.1,463.1z",
        type: "path",
      },
      {
        id: "watermark-4",
        d: "M586.7,630.2c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23L519,630l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.2,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C597.2,656.2,590.9,631.4,586.7,630.2z    M549.1,644.2l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L549.1,644.2z M523.2,636.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L523.2,636.6z M569.1,661.3c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9   l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L569.1,661.3z",
        type: "path",
      },
      {
        id: "watermark-5",
        d: "M116.4,525.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.3,0.4   l-4.4-0.8l-1.4-0.7l4-3.9l0.4-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2   l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.2c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C126.9,551.3,120.6,526.5,116.4,525.3z M78.8,539.3l1,0.4l-3.7,1.6l-1.1-0.6c-0.4-1.3-0.8-2.6-1.2-3.8L78.8,539.3z M52.9,531.7   l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L52.9,531.7z M98.8,556.5l-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.4,6.4   l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5   c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.3-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.2-0.1-0.3-0.2-0.4-0.3l22.2-9.7l3.3,12.4L98.8,556.5z",
        type: "path",
      },
      {
        id: "watermark-6",
        d: "M275.7,366.1c-5-1.4-17,5.7-23.2,8.5l-1.6-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.9-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.1c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5   l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2.1,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C286.2,392,279.9,367.3,275.7,366.1z    M238.1,380.1l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L238.1,380.1z M212.2,372.4l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L212.2,372.4z M258.1,397.2l-2-7.6l-9.4,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9   l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3   c8.4-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L258.1,397.2z",
        type: "path",
      },
      {
        id: "watermark-7",
        d: "M435,206.8c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L432,182l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4c-1.2-1.2-2.3-2.4-3.5-3.6l3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.5,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4   c7.2-3,31.2-11.8,33.6-16.4C445.5,232.8,439.1,208,435,206.8z M397.4,220.8l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L397.4,220.8z    M371.5,213.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L371.5,213.1z M417.4,237.9c-0.7-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   l-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.1-0.1-0.3-0.2-0.4-0.4l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L417.4,237.9z",
        type: "path",
      },
      {
        id: "watermark-8",
        d: "M594.2,47.6c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,11c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.4l-3.5-3.7c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.6-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C604.7,73.5,598.4,48.7,594.2,47.6z M556.6,61.6l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L556.6,61.6z M530.7,53.9l-8.1-1.1   c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L530.7,53.9z M576.6,78.7l-2-7.6l-9.4,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1   l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L576.6,78.7z",
        type: "path",
      },
    ],
  },
};

export default svgData;
