import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";

const DisplaySvg = ({ svgRef, data }) => {
  const { loading } = useSelector((state) => state.getCustomerDesign);
  
  return (
    <Fragment>
      <div className="container-fluid ddisplaay">
        {loading ? <Skeleton.Image active={loading} size={'large'}/> : <img ref={svgRef} src={data?.ImageWithWaterMark} alt="Displayed" />}

        <div className="Pricee">
          <p className="big">$10.00</p>
        </div>
      </div>
    </Fragment>
  );
};

export default DisplaySvg