import { combineReducers } from "@reduxjs/toolkit";
import getBodyPattern from "./Reducer/getBodyPatternReducer";
import getSleevePattern from "./Reducer/getSleevePatternReducer";
import getCapPattern from "./Reducer/getCapPatternReducer";
import postCustomerInfo from "./Reducer/postCustomerInfoReducer";
import getPayementIntent from "./Reducer/getPayementIntentReducer";
import postBuyShirt from "./Reducer/postBuyShirtReducer";
import getColor from "./Reducer/getColorReducer";
import postCustomShirt from "./Reducer/postCustomShirtReducer";
import getCustomerDesign from "./Reducer/getCustomerDesignReducer";
import postNewsLetter from "./Reducer/postNewsLetterReducer";

const rootReducer = combineReducers({
  getBodyPattern: getBodyPattern,
  getSleevePattern: getSleevePattern,
  getCapPattern: getCapPattern,
  getPayementIntent: getPayementIntent,
  postCustomerInfo: postCustomerInfo,
  postBuyShirt: postBuyShirt,
  getColor: getColor,
  postCustomShirt: postCustomShirt,
  getCustomerDesign: getCustomerDesign,
  postNewsLetter: postNewsLetter,
});
export default rootReducer