import React, { Fragment } from 'react'
import Navbar from '../components/Navbars/Navbar'
import Footer from '../components/Footer/Footer'
import Message from '../components/ThankYou/Message'

function ThankYou() {
    return (
        <Fragment>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <Message />
                </div>    
            </div>
            <Footer />
        </Fragment>
    )
}

export default ThankYou