import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: {},
};

const getCustomerDesignSlice = createSlice({
  name: "getCustomerDesign",
  initialState,
  reducers: {
    getCustomerDesignStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getCustomerDesignSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getCustomerDesignFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  getCustomerDesignStart,
  getCustomerDesignSuccess,
  getCustomerDesignFailure,
  clearError,
} = getCustomerDesignSlice.actions;

export default getCustomerDesignSlice.reducer;
