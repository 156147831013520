import React, { useState } from "react";
import { Form } from "antd";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
// import { loadStripe } from "@stripe/stripe-js";
import PayPalPaymentForm from "./PayPalPaymentForm";

const PaymentForm = ({ svgRef, designData, customerData }) => {
  const [value, setValue] = useState(2);
  // const handleRadioChange = (e) => {
  //   console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  return (
    <div className="row payment contact">
      <Form>
        <div className="col-sm-12">
          <h2>Payment Method</h2>
        </div>
        {/* <div className="col-sm-12">
          <Form.Item name="payment">
            <Radio.Group onChange={handleRadioChange} value={value}>
              <Space direction="vertical">
                <Radio value={1}>
                  <span className="fulll">
                    Pay by Credit Card <img src={money} alt="asd" />
                  </span>
                </Radio>
                <Radio value={2}>Pay by PayPal </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div> */}
      </Form>
      <div className="col-sm-12">
        <hr />
      </div>
      {value === 1 && (
        <StripePaymentForm
          customerData={customerData}
          designData={designData}
          svgRef={svgRef}
        />
      )}
      {value === 2 && (
        <PayPalPaymentForm
          designData={designData}
          customerData={customerData}
        />
      )}
    </div>
  );
};

export default PaymentForm;
