import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postBuyShirtStart,
  postBuyShirtSuccess,
  postBuyShirtFailure,
  clearLoading,
} from "../Reducer/postBuyShirtReducer";
import axios from "axios";
export const postBuyShirt = createAsyncThunk(
  "postBuyShirt",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postBuyShirtStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}CreateUserShirt`,
        values
      );
      const responseData = await response;

      thunkAPI.dispatch(postBuyShirtSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postBuyShirtFailure(error.response.data.message));
      thunkAPI.dispatch(clearLoading())
      throw error;
    }
  }
);
