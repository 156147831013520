import React, { Fragment } from 'react'
import {Tabs} from 'antd'
import ShirtOneSleeveMarkingSlider from './ShirtOneSleeveMarkingSlider';
import ListColor from '../ColorCompTab/ListColor';

const { TabPane } = Tabs;

const ShirtOneSleeveTab = ({handlesleevesColor, handleSleevesMarkingColor, handleSleeveId, sleeveColors, sleevesMarkingColor}) => {
    
    return (
        <Fragment>
            <div className="row boddyTab">
                <div className="col-sm-12">
                    
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Sleeves Colour"  key="1">
                            <ListColor handleColor={handlesleevesColor} colorsss={sleeveColors}/>
                        </TabPane>
                        <TabPane tab="Sleeves Marking" key="2" >
                            <ShirtOneSleeveMarkingSlider handleSleeveId={handleSleeveId}/>
                        </TabPane>
                        <TabPane tab="Sleeves Marking Colour" key="3">
                            <ListColor handleColor={handleSleevesMarkingColor} colorsss={sleevesMarkingColor}/>
                        </TabPane>                
                    </Tabs>        
                </div>
            </div>
        </Fragment>
    )
}

export default ShirtOneSleeveTab